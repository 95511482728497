<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('elearning_tim.trainee_selection')}}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <router-link to="trainee-selection" :class="'btn btn-success text-light'">{{ $t('elearning_tim.trainee_selection') }} {{ $t('globalTrans.list') }}</router-link>
                    </template>
                    <template v-slot:body>
                      <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                          <b-row>
                            <!-- <b-col lg="6" sm="6">
                              <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="circular_memo_no"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="circular_memo_no"
                                      v-model="formData.check_time"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      :placeholder="$t('elearning_iabm.circular_memo_no')"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                  <div class="invalid-feedback d-block" v-if="circularErrorMsg">
                                    {{ circularErrorMsg }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col> -->
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="circular_memo_no"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.circular_memo_no"
                                      :options="circularList"
                                      id="circular_memo_no"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                          <b-overlay :show="load">
                            <b-row>
                              <b-col lg="6" sm="6">
                                <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="fiscal_year_id"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="formData.fiscal_year_id"
                                        :options="fiscalYearList"
                                        id="fiscal_year_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        disabled
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col lg="6" sm="6" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">
                                <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="org_id"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="formData.org_id"
                                        :options="orgList"
                                        id="org_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        disabled
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col lg="6" sm="6">
                                <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="office_type_id"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('elearning_config.office_type')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="formData.office_type_id"
                                        :options="officeTypeList"
                                        id="office_type_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        disabled
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col lg="6" sm="6">
                                <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="office_id"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('globalTrans.office')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="formData.office_id"
                                        :options="officeList"
                                        id="office_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        disabled
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col lg="6" sm="6">
                                <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="training_type_id"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                      plain
                                      v-model="formData.training_type_id"
                                      :options="trainingTypeList"
                                      id="training_type_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      disabled
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col lg="6" sm="6">
                                <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="training_category_id"
                                    slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                      plain
                                      v-model="formData.training_category_id"
                                      :options="trainingCategoryList"
                                      id="training_category_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      disabled
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col lg="6" sm="6">
                                <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="training_title_id"
                                    slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                      plain
                                      v-model="formData.training_title_id"
                                      :options="trainingTitleList"
                                      id="training_title_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      disabled
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                            <b-overlay :show="loading">
                              <b-row>
                                <div class="col-md-12">
                                  <fieldset class="p-2 w-100">
                                    <legend class="px-2 w-50 shadow-sm">{{$t('elearning_iabm.training_applications')}} {{$t('globalTrans.list')}}</legend>
                                      <table class="table" style="width:100%">
                                        <thead class="thead">
                                          <tr>
                                            <th class="text-center">{{$t('globalTrans.sl_no')}}</th>
                                            <th>{{$t('globalTrans.name')}}</th>
                                            <th>{{$t('globalTrans.mobile')}}</th>
                                            <th>{{$t('globalTrans.designation')}}</th>
                                            <th>{{$t('globalTrans.select')}}</th>
                                          </tr>
                                        </thead>
                                          <tr v-for="(train,index) in formData.details" :key="index">
                                            <td class="text-center">{{$n(index+1)}}</td>
                                            <td>
                                              {{ ($i18n.locale==='bn') ? train.name_bn : train.name }}
                                              <small v-if="detailsErrors && detailsErrors['details.' + index + '.designation_id']" class="text-danger">
                                                {{ detailsErrors['details.'+index+'.designation_id'] }}
                                              </small>
                                            </td>
                                            <td>
                                              {{ train.mobile }}
                                              <small v-if="detailsErrors && detailsErrors['details.' + index + '.mobile']" class="text-danger">
                                                {{ detailsErrors['details.'+index+'.mobile'] }}
                                              </small>
                                            </td>
                                            <td>
                                              {{ ($i18n.locale==='bn') ? train.designation_name_bn : train.designation_name }}
                                              <small v-if="detailsErrors && detailsErrors['details.' + index + '.designation_id']" class="text-danger">
                                                {{ detailsErrors['details.'+index+'.designation_id'] }}
                                              </small>
                                            </td>
                                            <td>
                                              <b-form-checkbox
                                                :id="'checkbox-' + train.id"
                                                v-model="train.sort_status"
                                                :name="'checkbox-' + train.id"
                                                value=2
                                                unchecked-value=1
                                              >
                                              </b-form-checkbox>
                                              <small v-if="detailsErrors && detailsErrors['details.' + index + '.select']" class="text-danger">
                                                {{ detailsErrors['details.'+index+'.select'] }}
                                              </small>
                                            </td>
                                            <td>
                                            </td>
                                          </tr>
                                          <template v-if="formData.details.length === 0">
                                            <tr>
                                              <th colspan="5" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                                            </tr>
                                          </template>
                                      </table>
                                  </fieldset>
                                </div>
                              </b-row>
                            </b-overlay>
                            <div class="row">
                              <div class="col-sm-3"></div>
                              <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.save') }}</b-button>
                                  &nbsp;
                                  <router-link to="trainee-selection" class="mr-1 btn btn-danger">{{ $t('globalTrans.cancel') }}</router-link>
                              </div>
                            </div>
                          </b-overlay>
                        </b-form>
                      </ValidationObserver>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingApplicationAllList, trainerSortingStore, circularPublication, circularPublicationList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
    mixins: [ModalBaseMasterList],
    data () {
      return {
        search: {
          org_id: 0,
          mobile: ''
        },
        formData: {
          registration_for: 2,
          check_time: '',
          circular_memo_no: 0,
          fiscal_year_id: 0,
          office_type_id: 0,
          office_id: 0,
          org_id: 0,
          training_category_id: 0,
          training_type_id: 0,
          training_title_id: 0,
          sort: 3,
          details: []
        },
        officeTypeList: [],
        officeList: [],
        trainingCategoryList: [],
        trainingTitleList: [],
        load: false,
        loading: false,
        detailsErrors: [],
        circularList: [],
        circularLoading: false,
        circularErrorMsg: false,
        trainerProfileError: ''
      }
    },
    computed: {
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      trainingTypeList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      }
    },
    watch: {
      'formData.circular_memo_no': function (newValue) {
        this.getCircularMemoNo(newValue)
      },
      'formData.org_id': function (newValue) {
        this.officeTypeList = this.getOfficeTypeList(newValue)
      },
      'formData.office_type_id': function (newValue) {
        this.officeList = this.getOfficeList(newValue)
      },
      'formData.training_type_id': function (newValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      },
      'formData.training_category_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      }
    },
    mounted () {
      core.index()
      this.getCircularList()
      flatpickr('.fromDate', {})
    },
    methods: {
      async getCircularList () {
        this.circularLoading = true
        const serchData = {
          org_id: this.$store.state.dataFilters.orgId
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationList + '/' + 'tpm_training_calendar' + '/' + 1, serchData)
        if (!result.success) {
        } else {
          const listObject = result.data
          const tmpList = listObject.map((obj, index) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            } else {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            }
          })
          this.circularList = tmpList
        }
        this.circularLoading = false
      },
      async getCircularMemoNo (getCircularMemoNo) {
        if (getCircularMemoNo) {
          this.load = true
          const params = {
            circular_memo_no: getCircularMemoNo,
            table: 'iab_circular_publications'
          }
          const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublication, params)
          if (!result.success) {
            this.formData.fiscal_year_id = 0
            this.formData.org_id = 0
            this.formData.office_id = 0
            this.formData.office_type_id = 0
            this.formData.training_title_id = 0
            this.formData.training_type_id = 0
            this.formData.training_category_id = 0
            this.formData.sort = 3
            this.formData.details = []
            this.circularErrorMsg = 'Please Create Circular'
            this.load = false
          } else {
            this.circularErrorMsg = false
            const trData = result.data
            this.formData.fiscal_year_id = trData.fiscal_year_id
            this.formData.org_id = trData.org_id
            this.formData.office_id = trData.office_id
            this.formData.office_type_id = trData.office_type_id
            this.formData.training_title_id = trData.training_title_id
            this.formData.training_type_id = trData.training_type_id
            this.formData.training_category_id = trData.training_category_id
            this.load = false
            this.loadData()
          }
        }
      },
      async loadData () {
        this.loading = true
        const searchData = this.formData
        searchData.details = []
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainingApplicationAllList, searchData)
        if (result.success) {
          const datas = result.data.map(item => {
            const desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
            const desigData = {}
            if (typeof desigObj !== 'undefined') {
              desigData.designation_name = desigObj.text_en
              desigData.designation_name_bn = desigObj.text_bn
            } else {
              desigData.designation_name = ''
              desigData.designation_name_bn = ''
            }
            return Object.assign({}, item, desigData)
          })
          this.formData.details = datas
        } else {
          this.formData.details = []
        }
        this.loading = false
      },
      getOfficeTypeList (orgId) {
        const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
        if (orgId) {
          return OfficeTypeList.filter(item => item.org_id === orgId)
        }
        return OfficeTypeList
      },
      getOfficeList (officeTypeId) {
        const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
        if (officeTypeId) {
          return officeList.filter(item => item.office_type_id === officeTypeId)
        }
        return officeList
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      async createData () {
        this.load = true
        let result = null
        result = await RestApi.postData(trainingElearningServiceBaseUrl, trainerSortingStore, this.formData)
        if (result.success) {
          // this.$socket.emit('send-notification', result.notification)
          this.$toast.success({
            title: 'Success',
            message: this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          this.$refs.form.reset()
          this.$router.push('/training-e-learning-service/tim/trainee-selection')
        }
        this.load = false
      }
    },
    beforeDestroy () {
      clearInterval(this.inputTimer)
    }
}
</script>
